import api from './api'
const URL_BASE = process.env.REACT_APP_API

export const GetTokenApp = async () => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/TokenApp`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: `"${process.env.REACT_APP_KEYAPI}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const LoginUsuario = async (token, value) => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/Login`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
      body: `"${value}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const RecuperarSenha = async (email) => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/RecuperarLogin`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(email),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const IsHashValido = async (hash) => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/IsHashValido`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(hash),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const AltSenha = async (values) => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/AltSenha`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(values),
    })
    const json = req.json()
    return json
  } catch (error) {
    return undefined
  }
}

//Usuario
export const UsuarioGet = async (filters) => {
  try {
    const req = await api.post('/Participante/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Participante/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetById = async (Id) => {
  try {
    const req = await api.get(`/Participante/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetByNome = async (value) => {
  try {
    const req = await api.post(`/Participante/GetByNome`, value)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteAddFoto = async (formData) => {
  try {
    const req = await api.post('/Participante/AddFotoPortal', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioDelContaSendHash = async (value) => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/DelContaSendHash`, {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      body: `"${value}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const UsuarioDelContaConfirmacao = async (value) => {
  try {
    const req = await fetch(`${URL_BASE}/Participante/DelContaConfirmacao`, {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(value),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetCep = async (cep) => {
  try {
    const req = await api.get(`/Participante/GetCep?EndCEP=${cep}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Produto
export const ProdutoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Produto/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Produto/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoGetById = async (Id) => {
  try {
    const req = await api.get(`/Produto/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoGetCategorias = async () => {
  try {
    const req = await api.get(`/Produto/GetCategorias`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoGetEstoqueBaixo = async () => {
  try {
    const req = await api.get(`/Produto/GetEstoqueBaixo`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Produto/Midias
export const ProdutoGetMidias = async (produtoId) => {
  try {
    const req = await api.get(`/Produto/GetMidias?produtoId=${produtoId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoDelMidia = async (midiaId) => {
  try {
    const req = await api.get(`/Produto/DelMidia?midiaId=${midiaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoAddMidia = async (formData) => {
  try {
    const req = await api.post('/Produto/AddMidia', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return req.data
  } catch (error) {
    return undefined
  }
}
//Produto/Precos
export const ProdutoGetPrecos = async (produtoId) => {
  try {
    const req = await api.get(`/Produto/GetPrecos?produtoId=${produtoId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoDelPreco = async (id) => {
  try {
    const req = await api.get(`/Produto/DelPreco?precoId=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoGetPrecoById = async (id) => {
  try {
    const req = await api.get(`/Produto/GetPrecoById?precoId=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProdutoAddOrUpdatePreco = async (values) => {
  try {
    const req = await api.post('/Produto/AddOrUpdatePreco', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Promocao
export const PromocaoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Promocao/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PromocaoGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Promocao/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PromocaoGetById = async (Id) => {
  try {
    const req = await api.get(`/Promocao/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//MatEducativo
export const MatEducativoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/MatEducativo/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const MatEducativoGetById = async (Id) => {
  try {
    const req = await api.get(`/MatEducativo/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const MatEducativoGetAll = async () => {
  try {
    const req = await api.get('/MatEducativo/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const MatEducativoRemove = async (Id) => {
  try {
    const req = await api.delete(`/MatEducativo/Remove?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysParametros
export const SysParametroGet = async () => {
  try {
    const req = await api.get(`/SysParametro/GetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysParametroAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysParametro/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Pedido
export const PedidoGetRecentes = async () => {
  try {
    const req = await api.get('/Pedido/GetRecentes')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoAvaliacaoGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Pedido/AvaliacaoGetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoAvaliacaoAprovar = async (Id) => {
  try {
    const req = await api.post(`/Pedido/AvaliacaoAprovar?proAvaliacaoId=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoAvaliacaoRejeitar = async (Id) => {
  try {
    const req = await api.post(`/Pedido/AvaliacaoRejeitar?proAvaliacaoId=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Notificacao
export const NotificacaoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Notificacao/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const NotificacaoGetById = async (Id) => {
  try {
    const req = await api.get(`/Notificacao/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const NotificacaoGetAll = async () => {
  try {
    const req = await api.get('/Notificacao/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}

//CentroDist
export const CentroDistAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/CentroDist/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CentroDistGetById = async (Id) => {
  try {
    const req = await api.get(`/CentroDist/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CentroDistGetAll = async () => {
  try {
    const req = await api.get('/CentroDist/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}
