import { Route, Navigate, Routes, HashRouter } from 'react-router-dom'
import React, { Suspense, lazy, Fragment } from 'react'
import { ToastContainer } from 'react-toastify'
import { getToken } from '../../Reducers/storageApp'

import Login from '../../Pages/Login'
import Home from '../../Pages/Home'
import AccountDeletion from '../../Pages/AccountDeletion'

const User = lazy(() => import('../../Pages/User'))
const EducationalMat = lazy(() => import('../../Pages/EducationalMat'))
const Evaluation = lazy(() => import('../../Pages/Evaluation'))
const Parameters = lazy(() => import('../../Pages/Parameters'))
const Order = lazy(() => import('../../Pages/Order'))
const Product = lazy(() => import('../../Pages/Product'))
const Promotion = lazy(() => import('../../Pages/Promotion'))
const Notification = lazy(() => import('../../Pages/Notification'))
const CentroDist = lazy(() => import('../../Pages/CentroDist'))

const PrivateRoute = ({ component: Component }) => {
  const token = getToken()
  if (!token) return (window.location.href = '/#/Login')
  return Component
}

export default function App() {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">
                Aguarde enquanto carregamos todos os componentes
                <small> ... </small>
              </h6>
            </div>
          </div>
        }
      >
        <HashRouter>
          <Routes>
            <Route path="/" exact element={<PrivateRoute component={<Home />} />} />
            <Route path="/Home" element={<PrivateRoute component={<Home />} />} />

            <Route path="/User/*" element={<PrivateRoute component={<User />} />} />
            <Route path="/Product/*" element={<PrivateRoute component={<Product />} />} />
            <Route path="/Promotion/*" element={<PrivateRoute component={<Promotion />} />} />
            <Route path="/Order" element={<PrivateRoute component={<Order />} />} />
            <Route path="/Evaluation" element={<PrivateRoute component={<Evaluation />} />} />
            <Route path="/EducationalMat/*" element={<PrivateRoute component={<EducationalMat />} />} />
            <Route path="/Parameters" element={<PrivateRoute component={<Parameters />} />} />
            <Route path="/Notification/*" element={<PrivateRoute component={<Notification />} />} />
            <Route path="/CentroDist/*" element={<PrivateRoute component={<CentroDist />} />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<PrivateRoute component={<Navigate to="/Home" />} />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="/AccountDeletion/*" element={<AccountDeletion />} />
          </Routes>
        </HashRouter>
      </Suspense>

      <ToastContainer />
    </Fragment>
  )
}
