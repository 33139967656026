import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Wrapper, Container, CardLogo, Filter } from './styles'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import logo from '../../assets/images/logo-inversa.png'
import Conectar from './Conectar'
import Recuperar from './Recuperar'
import NovaSenha from './NovaSenha'

export default function Componente() {
  return (
    <Wrapper>
      <Filter>
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <Container>
            <CardLogo>
              <img src={logo} alt="Logo_App" />
            </CardLogo>
            <Routes>
              <Route exact path="/" element={<Conectar />} />
              <Route path="/recuperar" element={<Recuperar />} />
              <Route path="/pass/:id" element={<NovaSenha />} />
            </Routes>
          </Container>
        </ReactCSSTransitionGroup>
      </Filter>
    </Wrapper>
  )
}
