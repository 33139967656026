import React, { Fragment } from 'react'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import AppFooter from '../../Layout/AppFooter'

import { PageTitle } from '../../Components'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Card, CardTitle, CardBody, Col, Row } from 'reactstrap'

import CardOrders from './Orders'
import LowStock from './LowStock'
import PendingReviews from './PendingReviews'

export default function Dashboards() {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
              <div className="body-tabs body-tabs-layout">
                <PageTitle heading="Página Inicial" subheading="Resumo das operações no portal" icon="BsGridFill" />
                <div className="page-content">
                  <Row>
                    <Col lg="12">
                      <Card className="main-card mb-2">
                        <CardBody>
                          <CardTitle>AVALIAÇÕES DE PRODUTOS PENDENTES DE APROVAÇÃO</CardTitle>
                          <PendingReviews />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="main-card mb-2">
                        <CardBody>
                          <CardTitle>PEDIDOS RECENTES DE CLIENTES</CardTitle>
                          <CardOrders />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="main-card mb-2">
                        <CardBody>
                          <CardTitle>PRODUTOS COM ESTOQUE BAIXO</CardTitle>
                          <LowStock />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </ReactCSSTransitionGroup>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}
