export const NavAdmin = [
  { icon: 'fa fa-list', label: 'Página Inicial', to: '/#/' },
  { icon: 'bi bi-star-half', label: 'Avaliações', to: '/#/Evaluation' },
  { icon: 'fa fa-truck', label: 'Cent. Distribuição', to: '/#/CentroDist' },
  {
    icon: 'bi bi-mortarboard-fill',
    label: 'Material Educativo',
    content: [
      { label: 'Cadastro', to: '/#/EducationalMat/Add' },
      { label: 'Consulta', to: '/#/EducationalMat/Query' },
    ],
  },
  { icon: 'fa fa-bell', label: 'Push Notification', to: '/#/Notification' },

  { icon: 'fa fa-shopping-cart', label: 'Pedidos', to: '/#/Order' },
  {
    icon: 'fa fa-product-hunt',
    label: 'Produtos',
    content: [
      { label: 'Cadastro', to: '/#/Product/Add' },
      { label: 'Consulta', to: '/#/Product/Query' },
    ],
  },
  {
    icon: 'fa fa-percent',
    label: 'Promoções',
    content: [
      { label: 'Cadastro', to: '/#/Promotion/Add' },
      { label: 'Consulta', to: '/#/Promotion/Query' },
    ],
  },
  { icon: 'fa fa-gear', label: 'Parâmetros', to: '/#/Parameters' },
  {
    icon: 'fa fa-user',
    label: 'Usuários',
    content: [
      { label: 'Cadastro', to: '/#/user/Add' },
      { label: 'Consulta', to: '/#/user/Query' },
    ],
  },
]

export const NavCliente = [{ icon: 'fa fa-list', label: 'Home', to: '/#/' }]
