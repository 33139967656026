import React, { Fragment } from 'react'
import MetisMenu from 'react-metismenu'
import { NavAdmin, NavCliente } from './NavItems'
import { getUser } from '../../Reducers/storageApp'

export default function AppNav() {
  const { perfil } = getUser()
  let menuRender
  if (perfil === 'A') {
    menuRender = (
      <Fragment>
        <MetisMenu content={NavAdmin} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />
      </Fragment>
    )
  } else {
    menuRender = (
      <Fragment>
        <MetisMenu content={NavCliente} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />
      </Fragment>
    )
  }

  return <Fragment>{menuRender}</Fragment>
}
