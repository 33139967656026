import styled from 'styled-components'
import bgImagem from '../../assets/images/bg-image.jpg'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${bgImagem}); // linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.5)), url(${bgImagem});
  background-position: center;
  background-size: cover;

  > div {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4px 6px;
  border-bottom: 2px solid #b37400;
  width: 100%;
  background-color: #ec9900;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`

export const CardLogo = styled.div`
  > img {
    width: 34px;
  }
`

export const Filter = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  backdrop-filter: none;
  min-width: 328px;
  max-width: 468px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;

  padding-bottom: 10px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);

  @media (max-width: 688px) {
    width: 90%;
    margin: auto;
  }
`
export const Title = styled.p`
  margin: auto 0 auto;
  color: #fff;
  padding-right: 10px;
  font-size: 1.2rem;
  font-weight: 600;
`
export const TextInfo = styled.span`
  margin-right: auto;
  text-align: left;
  padding: 6px 0;
  line-height: 18px;
`

export const ContainerForm = styled.div`
  width: 100%;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 1.2rem;
    line-height: 1.334;
    letter-spacing: 0em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: var(--gray-dark);
  }
`
export const CardFooter = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 5px 0;
`

export const Button = styled.button`
  padding: 8px 20px;
  height: 38px;
  max-width: 250px;
  margin: 10px auto;

  font-weight: 600;
  font-size: 14px;
  color: var(--white);
  background: var(--danger) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 688px) {
    width: 100%;
  }
  > span {
    margin-left: 5px;
  }
`

export const BoxMsgSuccess = styled.div`
  path {
    fill: #ec278c;
  }

  > p {
    margin: 8px 0;
    font-size: 1.1rem;
  }
  > h2 {
    margin-top: 20px;
    color: #ec278c;
  }
`
