import React, { Fragment, useEffect, useState } from 'react'

import { BsEyeFill } from 'react-icons/bs'
import { Col, Table } from 'reactstrap'
import { ProdutoGetEstoqueBaixo } from '../../Service/ApiService'

export default function Componente() {
  // const Token = useSelector((state) => state.Token)
  const [dataGrid, setDataGrid] = useState([])
  async function loadGrid() {
    const response = await ProdutoGetEstoqueBaixo()
    if (response) setDataGrid(response)
  }

  useEffect(() => {
    loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Col className="tb-report position-relative table-responsive">
        <Table striped>
          <thead>
            <tr>
              <th>Código Ext</th>
              <th>Produto</th>
              <th>Estoque</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataGrid.map((d) => (
              <tr key={d.produtoId}>
                <td align="left">{d.produtoIdExt}</td>
                <td align="left">{d.nome}</td>
                <td align="right">{d.qtdEstoque}</td>
                <td style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-outline-primary btn-square btn-sm"
                    onClick={() => {
                      window.location.href = '/#/Product/Edit/' + d.produtoId
                    }}
                  >
                    <BsEyeFill />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Fragment>
  )
}
