import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState('')

  useEffect(() => {
    setstate(Mask(value))
    onChange({ target: { value: Mask(value), ...inputProps } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInput = (ev) => {
    var valMasked = Mask(ev.target.value)
    setstate(valMasked)
    if (onChange) {
      onChange({ target: { value: valMasked, ...inputProps } })
    }
  }

  const Mask = (value) => {
    if (value === undefined) return
    return value
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d)/, '$1 - $2')
      .replace(/(\d{1})(\d)/, '$1 - $2')
      .replace(/(\d{1})(\d)/, '$1 - $2')
      .replace(/(\d{1})(\d)/, '$1 - $2')
  }

  return <Input onChange={onInput} maxLength="17" type="text" value={state} {...inputProps} style={{ textAlign: 'center', fontSize: '1.5rem' }} />
}

export default InputComponent
