import React, { Fragment, useEffect, useState } from 'react'
import { moment } from '../../Components'
import { Badge, Col, Table } from 'reactstrap'
import { PedidoGetRecentes } from '../../Service/ApiService'

export default function Componente() {
  const [dataGrid, setDataGrid] = useState([])

  const getBadge = (situacao) => {
    switch (situacao) {
      case '10':
        return 'warning'
      case '20':
        return 'focus'
      case '30':
        return 'focus'
      case '40':
        return 'focus'
      case '90':
        return 'success'

      case '99':
        return 'dark'
      default:
        return 'danger'
    }
  }

  async function loadGrid() {
    const response = await PedidoGetRecentes()
    if (response) setDataGrid(response)
  }

  useEffect(() => {
    loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Col className="tb-report position-relative table-responsive">
        <Table striped>
          <thead>
            <tr>
              <th>Pedido Nº</th>
              <th>Pagamento</th>
              <th>Cliente</th>
              <th>Tipo</th>
              <th width="20%" style={{ textAlign: 'center' }}>
                Situação
              </th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            {dataGrid.map((d) => (
              <tr key={d.pedidoId}>
                <td align="left">{d.pedidoId}</td>
                <td align="left">{!d.authorizationDh ? '' : moment(new Date(d.authorizationDh)).format('DD/MMM HH:mm')}</td>
                <td align="left">{d.participanteNm}</td>
                <td align="left">{d.tpPedido}</td>
                <td style={{ textAlign: 'center' }}>
                  <Badge className="btn-block  badge-focus" color={getBadge(d.situacao)}>
                    {d.situacaoNm ? d.situacaoNm : d.situacao}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Fragment>
  )
}
