import React, { useState } from 'react'
import { Wrapper, Container, CardLogo, Filter, ContainerForm, Title, CardHeader, TextInfo, Button, CardFooter, BoxMsgSuccess } from './styles'
import logo from '../../assets/images/logo-inversa.png'
import { toast, Bounce, Loading, ReactCSSTransitionGroup, InputHash } from '../../Components'
import { BsTrash } from 'react-icons/bs'
import { Input } from 'reactstrap'
import { UsuarioDelContaConfirmacao, UsuarioDelContaSendHash } from '../../Service/ApiService'

import Lottie from 'react-lottie'
import animationData from './animationData.json'
const defaultOptions = { loop: false, autoplay: true, animationData: animationData }

export default function Componente() {
  const [values, setValues] = useState({ email: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [stepId, setStepId] = useState(1)

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmitEmail(ev) {
    ev.preventDefault()

    if (values.email.length < 6) {
      toast('Informe um e-mail válido', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    try {
      setIsLoading(true)

      const result = await UsuarioDelContaSendHash(values.email)

      if (result?.result) {
        setStepId(2)
      } else {
        toast('E-mail inválido', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

        setValues({ ...values, email: '' })
      }
    } catch {}
    setIsLoading(false)
  }

  async function onSubmitHash(ev) {
    ev.preventDefault()

    if (values.hash.length < 5) {
      toast('Código inválido', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    try {
      setIsLoading(true)

      const result = await UsuarioDelContaConfirmacao({ ...values, hash: values.hash.replace(' - ', '').replace(' - ', '').replace(' - ', '').replace(' - ', '').replace(' - ', '') })

      if (result?.result) {
        setStepId(3)
      } else {
        toast('Código de confirmação inválido', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

        setValues({ ...values, hash: '' })
      }
    } catch {}
    setIsLoading(false)
  }
  return (
    <>
      <Loading isVisible={isLoading} />

      <Wrapper>
        <Filter>
          <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <Container>
              <CardHeader>
                <CardLogo>
                  <img src={logo} alt="Logo_App" />
                </CardLogo>
                <Title>Excluir sua conta</Title>
              </CardHeader>

              <ContainerForm>
                {stepId < 3 && (
                  <>
                    <TextInfo>É uma pena que você queira excluir sua conta.</TextInfo>
                    <TextInfo>Ao excluir sua conta do Yuhuh, você não poderá recuperar o conteúdo ou as informações. Seu perfil e todo seu histórico será excluído.</TextInfo>
                  </>
                )}

                {stepId === 1 && (
                  <CardFooter onSubmit={onSubmitEmail}>
                    <TextInfo>Informe o e-mail de cadastro</TextInfo>
                    <Input type="email" placeholder="E-mail" name="email" autoFocus onChange={onChange} value={values.email} />

                    <Button type="submit">
                      <BsTrash size={18} /> <span>Excluir</span>
                    </Button>
                  </CardFooter>
                )}

                {stepId === 2 && (
                  <CardFooter onSubmit={onSubmitHash}>
                    <TextInfo>Informe o código de segurança</TextInfo>
                    <InputHash placeholder="Código" name="hash" autoFocus onChange={onChange} value={values.hash} />

                    <Button type="submit">
                      <BsTrash size={18} /> <span>Confirmar a exclusão</span>
                    </Button>
                  </CardFooter>
                )}

                {stepId === 3 && (
                  <CardFooter>
                    <BoxMsgSuccess>
                      <Lottie options={defaultOptions} style={{ maxHeight: '120px', maxWidth: '120px' }} />
                      <p>O seu pedido de exclusão foi recebido com sucesso.</p>
                      <p>Agradecemos pela parceria até aqui.</p>

                      <h2>Até mais!</h2>
                    </BoxMsgSuccess>
                  </CardFooter>
                )}
              </ContainerForm>
            </Container>
          </ReactCSSTransitionGroup>
        </Filter>
      </Wrapper>
    </>
  )
}
